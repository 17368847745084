import type { FC } from 'react';
import { useEffect, useContext } from 'react';
import AppContext from '../utils/appContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Icon from '../components/icons';
import BinBrowser from '../components/binbrowser';
interface BinProps {}
const Bin: FC<BinProps> = ({}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const context = useContext(AppContext);
  const navigate = useNavigate();

  //parse object to JSON

  const checkStatus = async () => {
    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch(process.env.REACT_APP_API_URL + '/check-status.php', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + storedJWT,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data == 'success') {
            console.log('success');
            setLoading(false);
          } else {
            localStorage.clear();
            context?.updateLoginStatus(false);
            navigate('/login');
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          localStorage.clear();
          context?.updateLoginStatus(false);
          navigate('/login');
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };
  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };
  useEffect(() => {
    checkStatus();
  }, []);

  return (
    <>
      <div className="relative z-10 flex justify-end px-5 py-3 border-b border-slate-100 md:pl-5 lg:pl-10 xl:pl-20 md:py-7 md:pr-7">
        <div className="flex items-center">
          <p
            className="hidden text-xs font-medium leading-6 md:block md:text-sm "
            aria-hidden="true"
          >
            {user?.firstname} {user?.lastname}
          </p>
          <p className="ml-2 text-slate-300">|</p>

          <a
            onClick={logout}
            className={`cursor-pointer hover:text-slate-500
                    group  gap-x-2 p-2 text-sm leading-6 flex justify-center lg:justify-start items-center`}
          >
            <Icon iconName={`logout`} />
            <span className="hidden lg:block">Logout</span>
          </a>
        </div>
      </div>
      <BinBrowser />
    </>
  );
};
export default Bin;
