import type { FC } from 'react';
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserList from '../components/userlist';
import RegisterUser from '../components/registeruser';
import RegisterGroup from '../components/registergroup';
import GroupList from '../components/grouplist';
import AppContext from '../utils/appContext';
import Icon from '../components/icons';
//make an infterface for the props

const Users: FC = ({}) => {
  const [counter, setCounter] = useState(0);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user') || '{}')
  );
  const [groups, setGroups] = useState<Array<any>>([]);
  const [showRegisterUser, setShowRegisterUser] = useState(false);
  const [showRegisterGroup, setShowRegisterGroup] = useState(false);
  const [showManageGroups, setShowManageGroups] = useState(false);
  const context = useContext(AppContext);
  const navigate = useNavigate();

  const updateCounter = () => {
    setCounter(counter + 1);
  };
  const checkStatus = async () => {
    const storedJWT = localStorage.getItem('jwt');

    if (storedJWT) {
      await fetch(process.env.REACT_APP_API_URL + '/check-status.php', {
        method: 'GET',
        mode: 'cors',
        headers: {
          Authorization: 'Bearer ' + storedJWT,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data == 'success') {
            console.log('success');
          } else {
            localStorage.clear();
            context?.updateLoginStatus(false);
            navigate('/login');
          }
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error);
          localStorage.clear();
          context?.updateLoginStatus(false);
          navigate('/login');
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };
  const getgroups = async () => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch(process.env.REACT_APP_API_URL + '/get-all-groups.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setGroups(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };
  const toggleRegisterUserModal = () => {
    setShowRegisterUser(!showRegisterUser);
  };
  const toggleRegisterGroupModal = () => {
    setShowRegisterGroup(!showRegisterGroup);
  };
  const toggleManageGroupsModal = () => {
    setShowManageGroups(!showManageGroups);
  };
  useEffect(() => {
    if (user.groups?.includes(0) || user.groups?.includes(1)) {
      getgroups();
    }
    checkStatus();
  }, []);

  const roles = JSON.parse(user.groups || '[]');

  return (
    <>
      <div className="relative z-10 flex justify-end px-5 py-3 border-b border-slate-100 md:pl-5 lg:pl-10 xl:pl-20 md:py-7 md:pr-7">
        <div className="flex items-center">
          <p
            className="hidden text-xs font-medium leading-6 md:block md:text-sm "
            aria-hidden="true"
          >
            {user?.firstname} {user?.lastname}
          </p>
          <p className="ml-2 text-slate-300">|</p>

          <a
            onClick={logout}
            className={`cursor-pointer hover:text-slate-500
                    group  gap-x-2 p-2 text-sm leading-6 flex justify-center lg:justify-start items-center`}
          >
            <Icon iconName={`logout`} />
            <span className="hidden lg:block">Logout</span>
          </a>
        </div>
      </div>
      <div className="flex gap-3 p-3 mb-3 lg:pt-10 md:pl-5 lg:pl-10 xl:pl-16 fade-in">
        <button
          onClick={toggleRegisterUserModal}
          className="px-2 py-2 text-xs border rounded-md md:px-4 md:text-base border-suzy-grey hover:bg-shade transition-bg"
        >
          Register a user
        </button>
        <button
          onClick={toggleRegisterGroupModal}
          className="px-2 py-2 text-xs border rounded-md md:px-4 md:text-base border-suzy-grey hover:bg-shade transition-bg"
        >
          Create a group
        </button>
        <button
          onClick={toggleManageGroupsModal}
          className="px-2 py-2 text-xs border rounded-md md:px-4 md:text-base border-suzy-grey hover:bg-shade transition-bg"
        >
          Manage groups
        </button>
      </div>
      <UserList refreshCounter={counter} groups={groups} />
      {(roles?.includes(0) || roles?.includes(1)) && showRegisterUser && (
        <div className="fixed top-0 left-0 z-10 grid content-center w-full h-full bg-opacity-50 p-7 bg-default">
          <div className="w-full md:w-[500px] max-w-full mx-auto">
            <RegisterUser
              groups={groups}
              toggleRegisterUserModal={toggleRegisterUserModal}
              refresh={updateCounter}
            />
          </div>
        </div>
      )}
      {roles?.includes(0) && showRegisterGroup && (
        <div className="fixed top-0 left-0 z-10 grid content-center w-full h-full bg-opacity-50 p-7 bg-default">
          <div className="w-full md:w-[500px] max-w-full mx-auto">
            <RegisterGroup
              toggleRegisterGroupModal={toggleRegisterGroupModal}
              refresh={updateCounter}
            />
          </div>
        </div>
      )}
      {roles?.includes(0) && showManageGroups && (
        <div className="fixed top-0 left-0 z-10 grid content-center w-full h-full bg-opacity-50 p-7 bg-default">
          <div className="w-full md:w-[500px] max-w-full mx-auto">
            <GroupList
              toggleManageGroupsModal={toggleManageGroupsModal}
              refreshCounter={counter}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default Users;
