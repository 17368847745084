import type { FC } from 'react';
import { useRef, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AppContext from '../utils/appContext';
import axios from 'axios';

interface editPersonalInformationProps {
  refresh: () => void;
  contact: any;
  closeModal: () => void;
}

const EditContactPersonalInformation: FC<editPersonalInformationProps> = ({
  refresh,
  contact,
  closeModal,
}) => {
  console.log(contact);
  const [loading, setLoading] = useState(false);

  const [firstname, setFirstname] = useState(contact.firstname);
  const [lastname, setLastname] = useState(contact.lastname);
  const [phone, setPhone] = useState(contact.phone);
  const [email, setEmail] = useState(contact.email);
  const [company, setCompany] = useState(contact.company);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const [selectedContactLists, setSelectedContactLists] = useState<any>([]);
  const [contactListMenu, setContactListMenu] = useState<any>([]);
  const [contactLists, setContactsLists] = useState<any>([]);
  const [showListMenu, setShowListMenu] = useState(false);

  const context = useContext(AppContext);

  const navigate = useNavigate();

  const refreshList = () => {
    refresh();
  };

  const logout = () => {
    localStorage.clear();
    context?.updateLoginStatus(false);
    navigate('/login');
  };

  const emptyForm = () => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setPhone('');
    setCompany('');
    setSelectedContactLists([]);
    setContactListMenu(contactLists);
  };

  const getContactLists = async () => {
    const storedJWT = localStorage.getItem('jwt');
    await fetch(process.env.REACT_APP_API_URL + '/get-contact-lists.php', {
      method: 'GET',
      mode: 'cors',
      headers: {
        Authorization: 'Bearer ' + storedJWT,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setContactsLists(data);
        setInitialContactLists(data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const selectListItem = (item: any) => {
    if (!selectedContactLists.includes(item)) {
      setSelectedContactLists([...selectedContactLists, item]);
      //remove from groups menu
      setContactListMenu(
        contactListMenu.filter((list: any) => list.ID !== item.ID)
      );
    }
    setShowListMenu(false);
  };

  const handleRemoveList = (listId: number) => {
    setSelectedContactLists(
      selectedContactLists.filter((list: any) => list.ID !== listId)
    );
    //add back to groups menu
    setContactListMenu([
      ...contactListMenu,
      contactLists.find((list: any) => list.ID === listId),
    ]);
    setShowListMenu(false);
  };

  const updateContact = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    const storedJWT = localStorage.getItem('jwt');
    const formData = new FormData();
    formData.append('id', contact.ID);
    formData.append('firstname', firstname);
    formData.append('lastname', lastname);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('company', company);
    let contactListIDs = selectedContactLists.map((list: any) => list.ID);
    //extract id from selectedGroups array
    formData.append('contact_list', JSON.stringify(contactListIDs));
    //check if any field is not empty
    if (
      firstname === '' &&
      lastname === '' &&
      email === '' &&
      phone === '' &&
      company === '' &&
      contactListIDs.length === 0
    ) {
      setError('At least one field is required');
      setLoading(false);
      return;
    }
    if (storedJWT) {
      await axios
        .post(process.env.REACT_APP_API_URL + '/update-contact.php', formData, {
          headers: {
            Authorization: 'Bearer ' + storedJWT,
          },
        })
        .then((response: any) => {
          if (response.data === 'Error decoding token: Expired token') {
            logout();
          }
          const jsonData = response.data;

          setLoading(false);
          if (jsonData.error.length > 0) {
            setError(jsonData.error.join('<br/>'));
          } else if (jsonData.success.length > 0) {
            setSuccess('Contact updated successfully'); // Join success messages with newlines
            // emptyForm();
            refreshList();
          } else {
            setError('Something went wrong, please try again.');
          }
        })
        .catch((e) => {
          console.log(e);
          setError('Failed, try again');
          setLoading(false);
        })
        .catch((error) => {
          // Handle network or server errors
          console.log('error');
          console.log(error?.response?.data?.error);

          setLoading(false);
        });
    } else {
      console.log('not logged in');
      localStorage.clear();
      context?.updateLoginStatus(false);
      navigate('/login');
    }
  };
  const setInitialContactLists = (completeList: any) => {
    const contactListArray = JSON.parse(contact.contact_list);
    const selectedLists = completeList.filter((list: any) =>
      contactListArray.includes(list.ID)
    );
    setSelectedContactLists(selectedLists);
    const menuLists = completeList.filter(
      (list: any) => !contactListArray.includes(list.ID)
    );
    setContactListMenu(menuLists);
  };

  const toggleListMenu = () => {
    setShowListMenu(!showListMenu);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        contactListRef.current &&
        !contactListRef.current.contains(event.target as Node)
      ) {
        setShowListMenu(false);
      }
    };
    getContactLists();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const contactListRef = useRef<HTMLDivElement>(null);

  return (
    <div className="inline-block w-full p-6 pt-6 pr-6 mt-10 bg-white rounded-lg shadow-md fade-in md:mt-5">
      <div className="flex justify-between mb-3">
        <h1 className="mb-4 text-xl text-default">Update contact</h1>
        <svg
          onClick={closeModal}
          className="cursor-pointer"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.40918 1.43213L13.9192 13.9421"
            stroke="#0C150A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.9189 1.43213L1.40895 13.9421"
            stroke="#0C150A"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div className="space-y-3">
        <div className="grid gap-5 md:grid-cols-2">
          <div>
            <label
              htmlFor="firstname"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              First name
            </label>

            <input
              id="firstname"
              name="firstname"
              type="text"
              required
              placeholder="First name"
              value={firstname}
              onChange={(event) => setFirstname(event.target.value)}
              className="block w-full px-4 py-2.5 text-sm outline-none placeholder-gray-400 border border-gray-200 rounded-md bg-shade"
            />
          </div>
          <div>
            <label
              htmlFor="lastname"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Last name
            </label>

            <input
              id="lastname"
              name="lastname"
              placeholder="Last name"
              type="text"
              required
              value={lastname}
              onChange={(event) => setLastname(event.target.value)}
              className="block w-full px-4 py-2.5 text-sm outline-none placeholder-gray-400 border border-gray-200 rounded-md bg-shade"
            />
          </div>

          <div>
            <label
              htmlFor="firstname"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              required
              placeholder="Email"
              autoComplete="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              className="block w-full px-4 py-2.5 text-sm outline-none placeholder-gray-400 border border-gray-200 rounded-md bg-shade"
            />
          </div>
          <div>
            <label
              htmlFor="phone"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Phone
            </label>

            <input
              id="phone"
              name="phone"
              type="text"
              required
              placeholder="Phone"
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
              className="block w-full px-4 py-2.5 text-sm outline-none placeholder-gray-400 border border-gray-200 rounded-md bg-shade"
            />
          </div>

          <div className="col-span-2 mb-2">
            <label
              htmlFor="company"
              className="block mb-2 text-sm font-medium text-gray-700"
            >
              Company
            </label>

            <input
              id="company"
              name="company"
              type="text"
              placeholder="Company"
              required
              value={company}
              onChange={(event) => setCompany(event.target.value)}
              className="block w-full px-4 py-2.5 text-sm outline-none placeholder-gray-400 border border-gray-200 rounded-md bg-shade"
            />
          </div>
        </div>

        <div className="mb-2">
          <label
            htmlFor="contact-list"
            className="block mb-2 text-sm font-medium text-gray-700"
          >
            Contact list
          </label>
          <div className="mt-1" id="contact-list" ref={contactListRef}>
            <div className="relative  w-full cursor-pointer bg-shade border-gray-200 border min-h-[43px] rounded-md">
              <div
                className="relative w-full cursor-pointer"
                onClick={toggleListMenu}
              >
                {selectedContactLists.length === 0 && (
                  <p className="absolute top-0 left-0 w-full px-4 py-2.5 text-sm text-gray-500">
                    Select groups
                  </p>
                )}
                <svg
                  className="absolute w-5 h-5 p-1 top-3 right-4"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.30859 1.38867L6.85208 6.93184L12.3953 1.38867"
                    stroke="#969795"
                    strokeWidth="1.7"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="relative flex flex-wrap p-1">
                <div
                  className="absolute w-full h-full"
                  onClick={toggleListMenu}
                ></div>
                {selectedContactLists.map((list: any) => {
                  return (
                    <div key={`selected-list-${list.ID}`}>
                      <div className="relative flex items-center px-2 m-1 bg-white border border-gray-200 rounded text-default">
                        <span className="text-sm ">{list.name}</span>
                        <button
                          type="button"
                          className="ml-2 text-[18px] text-red-400"
                          onClick={() => handleRemoveList(list.ID)}
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
              {showListMenu && (
                <div className="relative z-10 -top-1">
                  <div className="absolute grid w-full px-1 py-1 rounded-br-md rounded-bl-md bg-shade">
                    {contactListMenu.map((item: any) => {
                      return (
                        <div
                          onClick={() => selectListItem(item)}
                          key={`list-${item.ID}`}
                          className="flex items-center px-2 py-1.5 cursor-pointer hover:bg-gray-200"
                        >
                          <label className="text-sm text-gray-700 cursor-pointer">
                            {item.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>{' '}
        </div>
        <div className="pt-3">
          <div className="flex justify-between mt-7">
            <div className="grid content-center">
              <button
                onClick={emptyForm}
                className="text-sm text-gray-400 outline-none"
              >
                Reset to default
              </button>
            </div>

            <div className="flex items-center justify-end gap-7">
              <button
                onClick={closeModal}
                className="text-sm outline-none text-default"
              >
                Cancel
              </button>
              {loading ? (
                <div className="flex items-center justify-start">
                  <svg
                    className="inline-block w-5 h-5 mr-3 text-accent animate-spin"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span className="text-sm font-medium text-gray-500">
                    Uploading...
                  </span>
                </div>
              ) : (
                <button
                  className="inline-flex justify-center w-auto px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-default hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                  onClick={updateContact}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
        <p className="mt-2 text-sm font-medium text-red-600">{error}</p>
        <p className="mt-2 text-sm font-medium text-green-600">{success}</p>
      </div>
    </div>
  );
};

export default EditContactPersonalInformation;
